<template>
  <v-dialog v-model="bulk_work_orders_dialog" persistent max-width="850">
    <v-stepper v-model="bulk_work_order_stepper">
      <v-stepper-header>
        <v-stepper-step :complete="bulk_work_order_stepper > 1" step="1">
          Set Deadline
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="bulk_work_order_stepper > 2" step="2">
          View & Print
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="pb-5 px-3">
            <v-card-title class="text-h5">
              Bulk Create Work Orders
            </v-card-title>
            <v-container class="mt-3">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h4>Set Deadline</h4>
                </v-col>
              </v-row>
              <v-row>
                <!--Deadline Date Picker-->
                <v-col cols="12" sm="12" md="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="work_order_deadline"
                        label="Deadline"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        color="#3d2cdd"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="work_order_deadline"
                      no-title
                      scrollable
                      color="#3d2cdd"
                      show-adjacent-months
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#ce2458" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#33cc99"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>

            <!--Alerts-->
            <v-row>
              <v-col md="6" sm="6">
                <v-alert type="success" text icon="mdi-check">
                  <v-row>
                    <v-col class="grow">
                      Included:
                      <ul
                        v-for="order in selected_sales_orders.filter(
                          (order) =>
                            order.work_order_production_status === 'Not Started'
                        )"
                        :key="order.order_id"
                      >
                        <li>{{ order.order_number }}</li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col md="6" sm="6">
                <v-alert
                  type="error"
                  text
                  icon="mdi-close"
                  v-if="
                    selected_sales_orders.filter(
                      (order) =>
                        order.work_order_production_status !== 'Not Started'
                    ).length > 0
                  "
                >
                  <v-row>
                    <v-col class="grow">
                      Excluded (already have work orders):
                      <ul
                        v-for="order in selected_sales_orders.filter(
                          (order) =>
                            order.work_order_production_status !== 'Not Started'
                        )"
                        :key="order.order_id"
                      >
                        <li>{{ order.order_number }}</li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-card-actions class="mt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="#33cc99"
                class="white--text"
                elevation="0"
                :disabled="valid === false"
                @click="passBulkWorkOrders($event)"
                :loading="loading_bulk_work_orders"
              >
                Create Work Orders ({{
                  selected_sales_orders.filter(
                    (order) =>
                      order.work_order_production_status === "Not Started"
                  ).length
                }})
              </v-btn>

              <v-btn text @click="passBulkWorkOrdersClose($event)"
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <!--Step 2-->
        <v-stepper-content step="2">
          <v-card class="pb-5 px-3">
            <v-card-title class="text-h5"> Print Work Orders </v-card-title>
            <v-container>
              <v-row>
                <v-col md="12" sm="12">
                  <v-alert type="success" text icon="mdi-check">
                    <v-row>
                      <v-col class="grow">
                        {{ work_orders_array.length }} Work
                        {{
                          work_orders_array.length === 1 ? "order" : "orders"
                        }}
                        created successfully!
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col md="3" sm="3">
                  <h4>Work Order #</h4>
                </v-col>
                <v-col md="3" sm="3">
                  <h4>Customer</h4>
                </v-col>
                <v-col md="3" sm="3">
                  <h4>Deadline</h4>
                </v-col>
                <v-col md="3" sm="3">
                  <h4>Action</h4>
                </v-col>
              </v-row>
              <v-row
                v-for="work_order in work_orders_array"
                :key="work_order.work_order_id"
              >
                <v-col md="3" sm="3">
                  {{ work_order.work_order_number }}
                </v-col>
                <v-col md="3" sm="3">
                  {{ work_order.customer_name }}
                </v-col>
                <v-col md="3" sm="3">
                  {{ work_order.work_order_deadline }}
                </v-col>
                <v-col md="3" sm="3">
                  <v-btn
                    text
                    small
                    color="primary"
                    @click="printWorkOrder(work_order)"
                    ><v-icon left>mdi-printer</v-icon> Print Work Order</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions class="mt-3">
              <v-spacer></v-spacer>
              <v-btn text @click="passBulkWorkOrdersComplete($event)"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>
<script>
import mixinCompanyProfile from "../../../globalActions/mixin_CompanyProfile";
import { printWorkOrder } from "../data/external_sales_orders";
export default {
  name: "BulkWorkOrdersDialog",
  props: [
    "bulk_work_orders_dialog",
    "selected_sales_orders",
    "bulk_work_order_stepper",
    "work_orders_array",
    "loading_bulk_work_orders",
  ],
  mixins: [mixinCompanyProfile],
  data() {
    return {
      valid: false,
      work_order_deadline: null,
      menu: null,
      date: null,
    };
  },
  updated() {
    this.setValid();
  },
  methods: {
    printWorkOrder,
    setValid() {
      return this.work_order_deadline !== null
        ? (this.valid = true)
        : (this.valid = false);
    },
    passBulkWorkOrders() {
      this.$emit("triggerBulkWorkOrders", this.work_order_deadline);
    },
    passBulkWorkOrdersClose() {
      this.$emit("triggerBulkWorkOrdersClose", false, [], 1);
    },
    passBulkWorkOrdersComplete() {
      this.$emit("triggerBulkWorkOrdersComplete");
    },
  },
};
</script>
